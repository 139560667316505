import amex from './amex.vue'
import mastercard from './mastercard.vue'
import visa from './visa.vue'
import bacs from './bacs.vue'

export default {
  amex,
  mastercard,
  visa,
  bacs,
  'American Express': amex,
  MasterCard: mastercard,
  Visa: visa
}
